export default [
  {
    title: 'Home',
    icon: 'HomeIcon',
    route: 'dashboard-ecommerce',
    // children: [
    //   {
    //     title: 'صفحه اصلی',
    //     route: 'dashboard-ecommerce',
    //     resource: 'test',
    //     action: 'test',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //     resource: 'test',
    //     action: 'test',
    //   },
    // ],
  },
]
